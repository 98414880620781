import React, { useState, useEffect } from 'react';
import PocketBase from 'pocketbase';
import { motion, AnimatePresence } from "framer-motion";
import { useMeasure } from 'react-use';
import Search from './Search';
import MenuMobile from "./handheld/MenuMobile";

const pb = new PocketBase('https://t004080.app-palma.teide.app/');

const Manage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [cards, setCards] = useState([]);
  const [stock, setStock] = useState({});
  const [selectedCardId, setSelectedCardId] = useState('');
  const [quantity, setQuantity] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [ref] = useMeasure();
  const [arrivals, setArrivals] = useState([]);
  const [arrival, setArrival] = useState('');
  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
  };

  const cardVariants = {
    hidden: { x: -100, opacity: 0 },
    visible: custom => ({
      x: 0,
      opacity: 1,
      transition: { delay: custom * 0.1 }
    })
  };


  const typesIcons = {
    Psychic: "https://t004080.app-palma.teide.app/api/files/j8ufu7icg4lcltu/r3ydhr36rscnq63/psychic_ek0fkl11hX.png?token=",
    Metal: "https://t004080.app-palma.teide.app/api/files/j8ufu7icg4lcltu/84txqskfuaqtxnl/metal_0YqbfzsCJh.png?token=",
    Lightning: "https://t004080.app-palma.teide.app/api/files/j8ufu7icg4lcltu/qkcg6npmbclc3n4/lightning_yQ1l2vf2tY.png?token=",
    Grass: "https://t004080.app-palma.teide.app/api/files/j8ufu7icg4lcltu/ffq2nxcyu67msmr/grass_vOn8344glV.png?token=",
    Fire: "https://t004080.app-palma.teide.app/api/files/j8ufu7icg4lcltu/9j0j0dn8yl9n5sl/fire_fckUpuyUzQ.png?token=",
    Fighting: "https://t004080.app-palma.teide.app/api/files/j8ufu7icg4lcltu/udm6a6p06u6x1gs/fighting_P78HVdyi5i.png?token=",
    Fairy: "https://t004080.app-palma.teide.app/api/files/j8ufu7icg4lcltu/5a5ed0do84xcvq6/fairy_op9YsG0sku.png?token=",
    Dragon: "https://t004080.app-palma.teide.app/api/files/j8ufu7icg4lcltu/sjje3dir71g64em/dragon_wYgWpBAn5V.png?token=",
    Darkness: "https://t004080.app-palma.teide.app/api/files/j8ufu7icg4lcltu/krtxm0qbtk9uw61/darkness_w8CTmjEqVi.png?token=",
    Colorless: "https://t004080.app-palma.teide.app/api/files/j8ufu7icg4lcltu/o5lm496jrdoduw4/colorless_5R3n1dM7Ei.png?token=",
    Water: "https://t004080.app-palma.teide.app/api/files/j8ufu7icg4lcltu/gms6ig2t9xfbyuh/water_FhfQymj8Gw.png?token="
  };

  const habilityIcon = {
    hability: "https://t004080.app-palma.teide.app/api/files/i9wekxntpec3m8l/qefgwulr212c2bw/ability_ulIkWhPIrd.png?token="
  };

  useEffect(() => {
    fetchStock();
    fetchArrivals();
  }, []);

  useEffect(() => {
    if (!searchTerm) {
      setCards([]);
      return;
    }
    fetchCards();
  }, [searchTerm]);

  const fetchStock = async () => {
    try {
      const result = await pb.collection('sales').getList();
      const stockMap = result.items.reduce((acc, item) => {
        acc[item.card] = item;
        return acc;
      }, {});
      setStock(stockMap);
    } catch (error) {
      console.error('Error fetching stock:', error);
    }
  };

  const fetchCards = async () => {
    setLoading(true);
    try {
      const searchTerms = searchTerm.split(',').map(term => term.trim().toLowerCase());
      let resultItems = [];
      for (const term of searchTerms) {
        const filter = createSearchFilter(term);
        const result = await pb.collection('cards').getList(1, 70, {
          filter: filter,
        });
        resultItems = [...new Set([...resultItems, ...result.items])];
      }
      setCards(resultItems);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching cards:', error);
      setError('');
      setLoading(false);
    }
  };

  function createSearchFilter(searchTerm) {
    const [name, setptcgoCode, number] = searchTerm.split(/[\s,]+/);
    let filters = [];
    if (name && setptcgoCode) {
      filters.push(`name~"${name}"`);
      filters.push(`setptcgoCode~"${setptcgoCode}"`);
      if (number) {
        filters.push(`number="${number}"`); // Exact match for number
      }
    } else {
      const fields = ['name', 'setptcgoCode', 'number'];
      filters = fields.map(field => `${field}~"${searchTerm}"`);
    }
    return filters.join(number ? ' && ' : ' || ');
  }

  async function fetchArrivals() {
    try {
      const result = await pb.collection('arrivals').getList();
      setArrivals(result.items);
    } catch (error) {
      console.error('Error fetching arrivals:', error);
    }
  }

  const handleAddOrUpdateStock = async () => {
    if (!selectedCardId || quantity <= 0) return;

    const stockItem = stock[selectedCardId];
    const user = pb.authStore.model ? pb.authStore.model.id : null; // Get the user ID

    try {
      if (stockItem) {
        if (stockItem.user !== user) {
          throw new Error('You are not authorized to update this stock item.');
        }
        await pb.collection('sales').update(stockItem.id, { quantity: parseInt(quantity, 10) });
      } else {
        await pb.collection('sales').create({
          card: selectedCardId,
          quantity: parseInt(quantity, 10),
          user: user,
          arrivalid: arrival // mirrorid()
        });
      }
      fetchStock();
      setSelectedCardId('');
      setQuantity('');
    } catch (error) {
      console.error('Error updating/adding to stock:', error);
    }
  };

  const handleDeleteFromStock = async (cardId) => {
    const stockItem = stock[cardId];
    const user = pb.authStore.model ? pb.authStore.model.id : null; // Get the user ID
    if (!stockItem) return;
    try {
      if (stockItem.user !== user) {
        throw new Error('You are not authorized to delete this stock item.');
      }
      await pb.collection('sales').delete(stockItem.id);
      fetchStock();
    } catch (error) {
      console.error('Error deleting from stock:', error);
    }
  };

  return (
    <div className="flex flex-col container mx-auto p-4">
      <MenuMobile />
      <div className="flex flex-row justify-center items-center flex-wrap">
        <Search onSearch={handleSearch} />
      </div>
      {loading && <p>Loading...</p>}
      {error && <p className="text-red-500">{error}</p>}
      <h2 className="text-2xl p-2 text-justify">My Sales</h2>
      <div ref={ref} className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 py-2">
        <AnimatePresence>
          {Object.keys(stock).map((cardId, index) => {
            const stockItem = stock[cardId];
            const card = cards.find((c) => c.id === cardId);
            if (!card) return null;
            return (
              <motion.div
                key={card.id}
                variants={cardVariants}
                initial="hidden"
                animate="visible"
                custom={index}
                exit="hidden"
                drag="y"
                dragConstraints={{ top: 0, bottom: 0 }}
                onDragEnd={(event, info) => {
                  if (info.offset.y > 100) {
                    handleDeleteFromStock(cardId);
                  } else if (info.offset.y < -100) {
                    setSelectedCardId(card.id);
                    setQuantity(1);
                    handleAddOrUpdateStock();
                  }
                }}
                className={`rounded-xl space-y-2 cursor-pointer ${selectedCardId === card.id ? 'ring-2 ring-blue-500' : ''}`} onClick={() => { setSelectedCardId(card.id); setQuantity(stockItem.quantity); }}
              >
                <div className="bg-[#FDE047] p-4 rounded-xl">
                  {selectedCardId === card.id ? (
                    <div className="flex flex-row justify-between items-center space-x-2">
                      <input
                        type="string"
                        style={{ width: '100%' }}
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                        className="bg-[#00000030] p-2 rounded-lg w-20 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Quantity"
                      />
                      <button
                        className="bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-700"
                        onClick={handleAddOrUpdateStock}
                      >
                        Update
                      </button>
                    </div>
                  ) : (
                    <div className="flex flex-row justify-between items-center">
                      <div className="flex flex-col">
                        {card.name}
                      </div>
                      <div className="flex flex-col">
                        {card.setptcgoCode}
                      </div>
                      <div className="flex flex-col bg-red-500 text-white p-2 rounded-full w-8 h-8 flex justify-center items-center">
                        {stockItem.quantity}
                      </div>
                      <div className="flex flex-row">
                        {card.number}<p class="text-gray-400">/{card.setprintedtotal}</p>
                      </div>
                    </div>
                  )}
                </div>
                <div className="flex flex-row justify-between items-center space-x-2">
                  <img src={card.imageslarge} alt={card.name} className="w-full h-auto rounded-xl hover:shadow-2xl transition duration-300" />
                </div>
                {card.hability && (
                  <div className="flex flex-col items-left space-x-2 w-full p-4 bg-[#fbf06f90] rounded-xl shadow-lg">
                    <div className="flex flex-col items-left space-x-2 justify-between">
                      <div className="flex flex-row items-center justify-between space-x-2">
                        {card.hability && <img src={habilityIcon.hability} alt={card.hability} className="w-16" />}
                        {card.hability && <p className="font-bold text-xl text-red-500">{card.hability}</p>}
                      </div>
                      <div className="flex flex-row items-left space-x-2">
                        <p className="font-semibold text-md">{card.hability_description}</p>
                      </div>
                    </div>
                  </div>)}
                {card.attack_one && (
                  <div className="flex flex-col items-left space-x-2 w-full p-4 bg-[#fbf06f90] rounded-xl shadow-lg">
                    <div className="flex flex-col items-left space-x-2 justify-between">
                      <div className="flex flex-row items-left space-x-1 justify-between">
                        {card.attack_one_energy_cost_type_one && <img src={typesIcons[card.attack_one_energy_cost_type_one]} alt={card.attack_one_energy_cost_type_one} className="w-7 h-7 rounded-full" />}
                        {card.attack_one_energy_cost_type_two && <img src={typesIcons[card.attack_one_energy_cost_type_two]} alt={card.attack_one_energy_cost_type_two} className="w-7 h-7 rounded-full" />}
                        {card.attack_one_energy_cost_type_three && <img src={typesIcons[card.attack_one_energy_cost_type_three]} alt={card.attack_one_energy_cost_type_three} className="w-7 h-7 rounded-full" />}
                        {card.attack_one_energy_cost_type_four && <img src={typesIcons[card.attack_one_energy_cost_type_four]} alt={card.attack_one_energy_cost_type_four} className="w-7 h-7 rounded-full" />}
                        {card.attack_one_energy_cost_type_five && <img src={typesIcons[card.attack_one_energy_cost_type_five]} alt={card.attack_one_energy_cost_type_five} className="w-7 h-7 rounded-full" />}
                        <p className="font-bold text-xl">{card.attack_one}</p>
                        <p className="font-bold text-xl items text-red-500">{card.attack_one_damage}</p>
                      </div>
                    </div>
                    {card.attack_one_description && (
                      <div className="flex flex-col space-y-2 p-2 text-justify">
                        <div className="flex flex-row justify-between items-center space-x-2">
                          {card.attack_one_description && <p className="font-semibold text-left text-justify">{card.attack_one_description}</p>}
                        </div>
                      </div>)}
                  </div>)}
                {card.attack_two && (
                  <div className="flex flex-col items-left space-x-2 w-full p-4 bg-[#fbf06f90] rounded-xl shadow-lg">
                    <div className="flex flex-col items-left space-x-2 justify-between">
                      <div className="flex flex-row items-left space-x-1 justify-between">
                        {card.attack_two_energy_cost_type_one && <img src={typesIcons[card.attack_two_energy_cost_type_one]} alt={card.attack_two_energy_cost_type_one} className="w-7 h-7 rounded-full" />}
                        {card.attack_two_energy_cost_type_two && <img src={typesIcons[card.attack_two_energy_cost_type_two]} alt={card.attack_two_energy_cost_type_two} className="w-7 h-7 rounded-full" />}
                        {card.attack_two_energy_cost_type_three && <img src={typesIcons[card.attack_two_energy_cost_type_three]} alt={card.attack_two_energy_cost_type_three} className="w-7 h-7 rounded-full" />}
                        {card.attack_two_energy_cost_type_four && <img src={typesIcons[card.attack_two_energy_cost_type_four]} alt={card.attack_two_energy_cost_type_four} className="w-7 h-7 rounded-full" />}
                        {card.attack_two_energy_cost_type_five && <img src={typesIcons[card.attack_two_energy_cost_type_five]} alt={card.attack_two_energy_cost_type_five} className="w-7 h-7 rounded-full" />}
                        <p className="font-bold text-xl">{card.attack_two}</p>
                        <p className="font-bold text-xl items text-red-500">{card.attack_two_damage}</p>
                      </div>
                    </div>
                    {card.attack_two_description && (
                      <div className="flex flex-col space-y-2 p-2 text-justify">
                        <div className="flex flex-row justify-between items-center space-x-2">
                          {card.attack_two_description && <p className="font-semibold text-left text-justify">{card.attack_two_description}</p>}
                        </div>
                      </div>)}
                  </div>)}
              </motion.div>
            );
          })}
        </AnimatePresence>
      </div>
      <h2 className="text-2xl p-2 text-justify">Search Results</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 py-2">
        <AnimatePresence>
          {cards.map((card, index) => {
            const isInStock = !!stock[card.id];
            if (stock[card.id]) return null;
            return (
              <motion.div
                key={card.id}
                variants={cardVariants}
                initial="hidden"
                animate="visible"
                custom={index}
                exit="hidden"
                drag
                dragConstraints={{
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0
                }}
                onDragEnd={(e, info) => {
                  if (info.offset.y < 0) {
                    setQuantity(quantity + 1);
                    handleAddOrUpdateStock();
                  }
                }}
                className={`rounded-xl space-y-2 cursor-pointer ${selectedCardId === card.id ? 'ring-2 ring-blue-500' : ''}`}
                onClick={() => { setSelectedCardId(card.id); setQuantity(stock[card.id]?.quantity || 1); }}
              >
                <div className="bg-[#FDE047] p-4 rounded-xl">
                  {selectedCardId === card.id ? (
                    <div className="flex flex-row justify-between items-center space-x-2">
                      <input
                        type="string"
                        style={{ width: '100%' }}
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                        className="bg-[#00000030] p-2 rounded-lg w-20 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Quantity"
                      />
                      <button
                        className="bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-700"
                        onClick={handleAddOrUpdateStock}
                      >
                        Add
                      </button>
                      <button
                        className="bg-green-500 text-white p-2 rounded-lg hover:bg-green-700"
                        onClick={() => setSearchTerm(card.name)}
                      >
                        Similars
                      </button>
                      {/*  <select
                        value={arrival}
                        onChange={(e) => setArrival(e.target.value)}
                        className="bg-[#00000030] p-2 rounded-lg w-20 focus:outline-none focus:ring-2 focus:ring-blue-500"
                      >
                        <option value="">Select Arrival</option>
                        {arrivals.map((arrival) => (
                          <option key={arrival.id} value={arrival.id}>
                            {arrival.name}
                          </option>
                        ))}
                      </select> */}
                    </div>
                  ) : (
                    <div className="flex flex-row justify-between items-center space-x-4">
                      <div className="flex flex-col">
                        {card.name}
                      </div>
                      <div className="flex flex-col">
                        {card.setptcgoCode}
                      </div>
                      <div className="flex flex-row">
                        {card.number}<p class="text-gray-500">/{card.setprintedtotal}</p>
                      </div>
                    </div>)}
                </div>
                <div className="flex flex-row justify-between items-center space-x-2">
                  <img src={card.imageslarge} alt={card.name} className="w-full h-auto rounded-xl hover:shadow-2xl transition duration-300" />
                </div>
                {card.hability && (
                  <div className="flex flex-col items-left space-x-2 w-full p-4 bg-[#fbf06f90] rounded-xl shadow-lg">
                    <div className="flex flex-col items-left space-x-2 justify-between">
                      <div className="flex flex-row items-center justify-between space-x-2">
                        {card.hability && <img src={habilityIcon.hability} alt={card.hability} className="w-16" />}
                        {card.hability && <p className="font-bold text-xl text-red-500">{card.hability}</p>}
                      </div>
                      <div className="flex flex-row items-left space-x-2">
                        <p className="font-semibold text-md">{card.hability_description}</p>
                      </div>
                    </div>
                  </div>)}
                {card.attack_one && (
                  <div className="flex flex-col items-left space-x-2 w-full p-4 bg-[#fbf06f90] rounded-xl shadow-lg">
                    <div className="flex flex-col items-left space-x-2 justify-between">
                      <div className="flex flex-row items-left space-x-1 justify-between">
                        {card.attack_one_energy_cost_type_one && <img src={typesIcons[card.attack_one_energy_cost_type_one]} alt={card.attack_one_energy_cost_type_one} className="w-7 h-7 rounded-full" />}
                        {card.attack_one_energy_cost_type_two && <img src={typesIcons[card.attack_one_energy_cost_type_two]} alt={card.attack_one_energy_cost_type_two} className="w-7 h-7 rounded-full" />}
                        {card.attack_one_energy_cost_type_three && <img src={typesIcons[card.attack_one_energy_cost_type_three]} alt={card.attack_one_energy_cost_type_three} className="w-7 h-7 rounded-full" />}
                        {card.attack_one_energy_cost_type_four && <img src={typesIcons[card.attack_one_energy_cost_type_four]} alt={card.attack_one_energy_cost_type_four} className="w-7 h-7 rounded-full" />}
                        {card.attack_one_energy_cost_type_five && <img src={typesIcons[card.attack_one_energy_cost_type_five]} alt={card.attack_one_energy_cost_type_five} className="w-7 h-7 rounded-full" />}
                        <p className="font-bold text-xl">{card.attack_one}</p>
                        <p className="font-bold text-xl items text-red-500">{card.attack_one_damage}</p>
                      </div>
                    </div>
                    {card.attack_one_description && (
                      <div className="flex flex-col space-y-2 p-2 text-justify">
                        <div className="flex flex-row justify-between items-center space-x-2">
                          {card.attack_one_description && <p className="font-semibold text-left text-justify">{card.attack_one_description}</p>}
                        </div>
                      </div>)}
                  </div>)}
                {card.attack_two && (
                  <div className="flex flex-col items-left space-x-2 w-full p-4 bg-[#fbf06f90] rounded-xl shadow-lg">
                    <div className="flex flex-col items-left space-x-2 justify-between">
                      <div className="flex flex-row items-left space-x-1 justify-between">
                        {card.attack_two_energy_cost_type_one && <img src={typesIcons[card.attack_two_energy_cost_type_one]} alt={card.attack_two_energy_cost_type_one} className="w-7 h-7 rounded-full" />}
                        {card.attack_two_energy_cost_type_two && <img src={typesIcons[card.attack_two_energy_cost_type_two]} alt={card.attack_two_energy_cost_type_two} className="w-7 h-7 rounded-full" />}
                        {card.attack_two_energy_cost_type_three && <img src={typesIcons[card.attack_two_energy_cost_type_three]} alt={card.attack_two_energy_cost_type_three} className="w-7 h-7 rounded-full" />}
                        {card.attack_two_energy_cost_type_four && <img src={typesIcons[card.attack_two_energy_cost_type_four]} alt={card.attack_two_energy_cost_type_four} className="w-7 h-7 rounded-full" />}
                        {card.attack_two_energy_cost_type_five && <img src={typesIcons[card.attack_two_energy_cost_type_five]} alt={card.attack_two_energy_cost_type_five} className="w-7 h-7 rounded-full" />}
                        <p className="font-bold text-xl">{card.attack_two}</p>
                        <p className="font-bold text-xl items text-red-500">{card.attack_two_damage}</p>
                      </div>
                    </div>
                    {card.attack_two_description && (
                      <div className="flex flex-col space-y-2 p-2 text-justify">
                        <div className="flex flex-row justify-between items-center space-x-2">
                          {card.attack_two_description && <p className="font-semibold text-left text-justify">{card.attack_two_description}</p>}
                        </div>
                      </div>)}
                  </div>)}
              </motion.div>
            );
          })}
        </AnimatePresence>
      </div>
    </div >
  );
}

export default Manage;
