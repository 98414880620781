// MaterialVerticalMenu.js
import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import PocketBase from 'pocketbase';
import Icon from '../../../../../frontdesk/desk/UniversalIcons';

const MaterialVerticalMenu = () => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const pb = new PocketBase('https://t004080.app-palma.teide.app/');
    const UniversalIcons = Icon;
    const userRole = pb.authStore.model ? pb.authStore.model.role : null;

    const handleLogout = () => {
        pb.authStore.clear();
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        localStorage.removeItem('email');
        navigate('/');
    };

    const buttonSize = "p-4"; // Tamaño del botón para mantener un círculo perfecto

    const variants = {
        open: { opacity: 1, scale: 1, y: -10, transition: { type: "sd", stiffness: 100, damping: 10 } },
        closed: { opacity: 0, scale: 1, y: 20 }
    };

    return (
        <div>
            <motion.button
                className={`fixed bottom-10 left-5 text-black font-bold rounded-full cursor-pointer z-10 text-xl bg-[#ffffff90] text-center rounded-full shadow-2xl border-2 border-black ${buttonSize}`}
                onClick={() => setIsOpen(!isOpen)}
                whileTap={{ scale: 0.9 }}
            >
                <div className="flex">
                    <UniversalIcons name="ArrowUp" />
                </div>
            </motion.button>
            <AnimatePresence>
                {isOpen && (
                    <div className="fixed bottom-28 flex flex-col text-xl text-center z-10">
                        {userRole === 'manager' && (
                            <>
                                <motion.button
                                    className={`bg-[#fabd81] text-black ${buttonSize} rounded-full cursor-pointer mb-2`}
                                    initial="closed"
                                    animate="open"
                                    exit="closed"
                                    variants={variants}
                                    whileTap={{ scale: 0.9 }}
                                    onClick={() => navigate('/sync')}
                                >
                                    Sync
                                </motion.button>
                                <motion.button
                                    className={`bg-[#fabd81] text-black ${buttonSize} rounded-full cursor-pointer mb-2`}
                                    initial="closed"
                                    animate="open"
                                    exit="closed"
                                    variants={variants}
                                    whileTap={{ scale: 0.9 }}
                                    onClick={() => navigate('/creator')}
                                >
                                    Creator
                                </motion.button>
                                {/* addbulkone and addbulktwo */}
                                <motion.button
                                    className={`bg-[#fabd81] text-black ${buttonSize} rounded-full cursor-pointer mb-2`}
                                    initial="closed"
                                    animate="open"
                                    exit="closed"
                                    variants={variants}
                                    whileTap={{ scale: 0.9 }}
                                    onClick={() => navigate('/addbulkone')}
                                >
                                    +Bulk 1
                                </motion.button>
                                <motion.button
                                    className={`bg-[#fabd81] text-black ${buttonSize} rounded-full cursor-pointer mb-2`}
                                    initial="closed"
                                    animate="open"
                                    exit="closed"
                                    variants={variants}
                                    whileTap={{ scale: 0.9 }}
                                    onClick={() => navigate('/addbulktwo')}
                                >
                                    +Bulk 2
                                </motion.button>
                            </>
                        )}
                        <motion.button
                            className={`bg-[#fabd81] text-black ${buttonSize} rounded-full cursor-pointer mb-8`}
                            initial="closed"
                            animate="open"
                            exit="closed"
                            variants={variants}
                            whileTap={{ scale: 0.9 }}
                            onClick={() => navigate('/')}
                        >
                            Manage
                        </motion.button>
                        {/*   <motion.button
                            className={`bg-[#6fc7ff] text-black ${buttonSize} rounded-full cursor-pointer mb-8`}
                            initial="closed"
                            animate="open"
                            exit="closed"
                            variants={variants}
                            whileTap={{ scale: 0.9 }}
                            onClick={() => navigate('/sales')}
                        >
                            Sales
                        </motion.button> */}
                        {/* now one for /newcorrespondence */}
                        {/*      <motion.button
                            className={`bg-[#9dfbc3] text-black ${buttonSize} rounded-full cursor-pointer mb-2`}
                            initial="closed"
                            animate="open"
                            exit="closed"
                            variants={variants}
                            whileTap={{ scale: 0.9 }}
                            onClick={() => navigate('/newcorrespondence')}
                        >
                            Arrivals
                        </motion.button> */}
                        <motion.button
                            className={`bg-[#feb4c2] text-black ${buttonSize} rounded-full cursor-pointer mb-2`}
                            initial="closed"
                            animate="open"
                            exit="closed"
                            variants={variants}
                            whileTap={{ scale: 0.9 }}
                            onClick={handleLogout}
                        >
                            Logout
                        </motion.button>
                    </div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default MaterialVerticalMenu;