// src/icons/repo/Flame.js
import React from 'react';

const Flame = () => (
<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
  <g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" transform="translate(5 3)">
    <path d="M5.5,16 C8.53756612,16 11,13.5714286 11,9.28571429 C11,6.42857143 9.16666667,3.33333333 5.5,0 C1.83333333,3.33333333 0,6.42857143 0,9.28571429 C0,13.5714286 2.46243388,16 5.5,16 Z"/>
    <path d="M5.5,16 C7.01878306,16 8.25,14.7857143 8.25,12.6428571 C8.25,11.2142857 7.33333333,9.66666667 5.5,8 C3.66666667,9.66666667 2.75,11.2142857 2.75,12.6428571 C2.75,14.7857143 3.98121694,16 5.5,16 Z"/>
  </g>
</svg>

);

export default Flame;
