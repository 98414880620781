import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PocketBase from 'pocketbase';
import Login from '../airport/control/gate/Login'; // Assuming Login.js is in the same directory

const pb = new PocketBase('https://t004080.app-palma.teide.app/');

const App = () => {
	const navigate = useNavigate();
	const user = pb.authStore.model;

	useEffect(() => {
		if (user) {
			if (user.verified) {
				navigate('/manage');
			} else {
				navigate('/activate');
			}
		}
	}, [user, navigate]);

	return (
		<div>
			<Login />
		</div>
	);
};

export default App;