import React from 'react';

const Activate = () => {
    return (
        <div className="flex items-center justify-center h-screen bg-gray-200">
            <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <h1 className="block text-gray-700 text-sm font-bold mb-2">Account Activation</h1>
                <p className="text-gray-700 text-base">
                    Please go to your email and activate your account,
                    then you can <a href="/login" className="text-blue-500">login</a>.
                </p>
            </div>
        </div>
    );
};

export default Activate;