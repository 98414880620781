import React from 'react';

const Landing = () => {
    return (
        <div className="flex flex-wrap justify-center bg-gradient-to-r from-blue-500 to-purple-600 text-white min-h-screen p-4 sm:p-0">
            {/* Main Landing Area */}
            <div className="flex flex-col text-center items-center justify-center w-full sm:w-1/2 lg:w-1/4 p-4">
                <h1 className="text-4xl sm:text-6xl font-bold mb-3">EpicEvils TCG Hub</h1>
                <p className="text-xl sm:text-2xl text-center mb-10">Sync, Search, and Manage Your TCG Pokemon Competitive Cards</p>
                <button className="px-4 sm:px-8 py-2 sm:py-4 bg-white text-blue-700 rounded-lg font-bold text-lg sm:text-xl hover:bg-gray-200 transition duration-300 ease-in-out"
                    onClick={() => window.location.href = '/signup'}
                >
                    Get Started
                </button>
                <p className="mt-5 text-sm sm:text-lg">A Pencil Works, LLC Initiative</p>
            </div>
            {/* Sync Section */}
            <div className="flex flex-col items-center justify-center w-full sm:w-1/2 lg:w-1/4 p-4 bg-blue-700">
                <h2 className="text-3xl sm:text-4xl font-bold mb-3">Sync Your Collection</h2>
                <p className="text-lg sm:text-xl text-center mb-5">
                    Easily synchronize your TCG Pokemon card collection with our platform. Ensure your collection is always up-to-date and accessible from anywhere.
                </p>
            </div>
            {/* Search Section */}
            <div className="flex flex-col items-center justify-center w-full sm:w-1/2 lg:w-1/4 p-4">
                <h2 className="text-3xl sm:text-4xl font-bold mb-3">Search Effortlessly</h2>
                <p className="text-lg sm:text-xl text-center mb-5">
                    Find any card in your collection in seconds. Use advanced filters to narrow down your search for that one elusive card.
                </p>
            </div>
            {/* Manage Section */}
            <div className="flex flex-col items-center justify-center w-full sm:w-1/2 lg:w-1/4 p-4 bg-blue-700">
                <h2 className="text-3xl sm:text-4xl font-bold mb-3">Manage with Ease</h2>
                <p className="text-lg sm:text-xl text-center mb-5">
                    Organize your cards like never before. Sort, tag, and manage your cards with our intuitive interface.
                </p>
            </div>
        </div>
    );
};

export default Landing;