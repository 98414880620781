import React, { useState } from 'react';
import PocketBase from 'pocketbase'; // Assuming you have a PocketBase module

const AddBulkFromList = () => {
    const [cards, setCards] = useState([]);
    const [file, setFile] = useState(null);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleFileUpload = async () => {
        const reader = new FileReader();
        reader.onload = async (e) => {
            const lines = e.target.result.split('\n');
            const cardsToAdd = lines.map(line => {
                const [quantity, name, setCode, number] = line.split(' ');
                return { quantity, name, setCode, number };
            });
            setCards(cardsToAdd);
        };
        reader.readAsText(file);
    };

    const handleAddCards = async () => {
        for (let card of cards) {
            const searchQuery = `${card.name} ${card.setCode}${card.number}/${card.setprintedtotal}`;
            const results = await PocketBase.search(searchQuery);
            if (results.length > 0) {
                // Add the first result to the user's collection
                await PocketBase.addCard(results[0].id, card.quantity);
            }
        }
    };

    return (
        <div>
            <input type="file" onChange={handleFileChange} />
            <button onClick={handleFileUpload}>Upload</button>
            <button onClick={handleAddCards}>Add Cards</button>
            {cards.map((card, index) => (
                <div key={index}>
                    <img src={card.imageUrl} alt={card.name} />
                    <p>{card.name}</p>
                    <p>Quantity: {card.quantity}</p>
                </div>
            ))}
        </div>
    );
};

export default AddBulkFromList;