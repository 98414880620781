// UniversalIcons.js
import React from 'react';

// Suponiendo que los íconos están en src/icons/repo y son componentes de React
import * as Icons from './repo';

const UniversalIcons = ({ name }) => {
  const IconComponent = Icons[name];

  if (!IconComponent) {
    // Retornar un ícono por defecto o null si el ícono solicitado no existe
    return <div>Ícono no encontrado</div>;
  }

  return <IconComponent />;
};

export default UniversalIcons;
