import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PocketBase from 'pocketbase';
import Patrol from '../airport/control/Patrol';
import MobileComponent from '../airport/control/gate/plane/passenger/handheld/SalesManagerMobile';
import DesktopComponent from '../airport/control/gate/plane/passenger/SalesManager';

const pb = new PocketBase('https://t004080.app-palma.teide.app/');

const App = () => {
	const navigate = useNavigate();
	const user = pb.authStore.model;

	useEffect(() => {
		if (user) {
			if (!user.verified) {
				navigate('/activate');
			}
		} else {
			navigate('/');
		}
	}, [user, navigate]);

	return (
		<div>
			<Patrol
				mobileComponent={<MobileComponent />}
				desktopComponent={<DesktopComponent />}
			/>
		</div>
	);
};

export default App;