import React, { useEffect } from 'react';

const Meta = ({ title, description, keywords }) => {
    useEffect(() => {
        document.title = title;
        // Update meta tags
        updateMetaTag('description', description);
        updateMetaTag('keywords', keywords);

        // Open Graph (OG) and Twitter meta tags for better social media integration
        updateMetaTag('og:title', title, 'property');
        updateMetaTag('og:description', description, 'property');
        updateMetaTag('twitter:title', title);
        updateMetaTag('twitter:description', description);

        // Inject the umami script for analytics
        const script = document.createElement('script');
        script.defer = true;
        script.src = "https://umami-uo044sw.app-palma.teide.app/script.js";
        script.dataset.websiteId = "826f719d-2c79-40ee-b7ca-d62606fa8898";
        document.head.appendChild(script);

        return () => {
            // Cleanup script to avoid duplicates
            document.head.removeChild(script);
        };

        function updateMetaTag(name, content, attr = 'name') {
            let element = document.head.querySelector(`meta[${attr}="${name}"]`);
            if (!element) {
                element = document.createElement('meta');
                element.setAttribute(attr, name);
                document.head.appendChild(element);
            }
            element.setAttribute('content', content);
        }
    }, [title, description, keywords]);

    return null; // This component does not render anything to the DOM directly
};

Meta.defaultProps = {
    title: 'EpicEvils TCG Hub',
    description: 'Sync, Search, and Manage Your TCG Pokemon Competitive Cards',
    keywords: 'Pokemon, TCG, Sync, Search, Manage, Cards, Competitive',
};

export default Meta;
