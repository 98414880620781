import React from 'react';

const Banner = ({ text, colorHex, paddingText, colorText, textAlign, fontFamilies, fontSize }) => {
    return (
        <div style={{
            backgroundColor: colorHex,
            padding: paddingText,
            color: colorText,
            textAlign: textAlign,
            fontFamily: fontFamilies,
            fontSize: fontSize
        }}>
            {text}
        </div>
    );
};

export default Banner;
