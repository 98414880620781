import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import FrontDesk from "./airport/frontdesk/Screen";
import Banner from "./airport/frontdesk/Banner";
import Home from "./pages";
import Sync from "./pages/sync";
import Manage from "./pages/manage";
import Sales from "./pages/sales";
import Signup from "./pages/signup";
import Login from "./pages/login";
import Activate from "./pages/activate";
import Meta from "./airport/Camera";
/* import Card from "./airport/control/gate/plane/passenger/Card";
 */import NewCorrespondence from "./airport/control/gate/plane/passenger/remote/NewCorrespondence";
import Decks from './airport/control/gate/plane/passenger/bag/DeckCreator';
import AddBulkFromListC1 from './airport/helpdesk/AddBulkFromListC1';
import AddBulkFromListC2 from './airport/helpdesk/AddBulkFromListC2';

import { motion } from "framer-motion";

function FrontDeskComponent() {
	const [lastYPos, setLastYPos] = useState(0);
	const [shouldShowActions, setShouldShowActions] = useState(true);
	const location = useLocation();
	const shouldShowFrontDesk = ["/", "/signup", "/login", "/activate"].includes(location.pathname);

	useEffect(() => {
		function handleScroll() {
			const yPos = window.scrollY;
			const isScrollingUp = yPos < lastYPos;

			setShouldShowActions(isScrollingUp);
			setLastYPos(yPos);
		}

		window.addEventListener("scroll", handleScroll, false);

		return () => {
			window.removeEventListener("scroll", handleScroll, false);
		};
	}, [lastYPos]);

	return shouldShowFrontDesk && (
		<motion.div
			className="fixed bottom-5 bg-black text-white p-4 text-xl text-center rounded-full shadow-lg"
			initial={{ y: 1000 }}
			animate={{ y: shouldShowActions ? 0 : 1000 }}
			transition={{ type: "spring", stiffness: 100, damping: 20 }}
		>
			<FrontDesk />
		</motion.div>
	);
}

function App() {
	return (
		<Router>
			<Meta />
			<div className="flex flex-col">
				<Banner
					text="Beta access to Deckmaker"
					colorText="#000000"
					paddingText="10px"
					textAlign="center"
					color="#000000"
					fontFamilies="Arial, sans-serif"
					fontSize="20px"
				/>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/manage" element={<Manage />} />
					<Route path="/sales" element={<Sales />} />
					<Route path="/sync" element={<Sync />} />
					<Route path="/signup" element={<Signup />} />
					<Route path="/login" element={<Login />} />
					<Route path="/activate" element={<Activate />} />
					{/* <Route path="/card" element={<Card />} /> */}
					<Route path="/decks" element={<Decks />} />
					<Route path="/newcorrespondence" element={<NewCorrespondence />} />
					<Route path="/addbulkone" element={<AddBulkFromListC1 />} />
					<Route path="/addbulktwo" element={<AddBulkFromListC2 />} />
				</Routes>
			</div>
			<div className="items-center justify-center flex bg-gray-200">
				<FrontDeskComponent />
			</div>
		</Router>
	);
}

export default App;