import React, { useState } from 'react';
import PocketBase from 'pocketbase';
import { useNavigate } from 'react-router-dom';
import Message from '../../frontdesk/Arrivals';

const pb = new PocketBase('https://t004080.app-palma.teide.app/');

const Signup = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [avatar, setAvatar] = useState(null);
    const [role, setRole] = useState('user');
    const [gdpr, setGdpr] = useState(false);
    const [terms, setTerms] = useState(false);
    const [beta, setBeta] = useState(true);
    const [marketing, setMarketing] = useState(true);
    const navigate = useNavigate();
    const [show, setShow] = useState(false);

    const handleSignup = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('username', username);
        formData.append('email', email);
        formData.append('password', password);
        formData.append('passwordConfirm', passwordConfirm);
        formData.append('role', 'user');
        formData.append('gdpr', gdpr);
        formData.append('terms', terms);
        formData.append('beta', beta);
        formData.append('marketing', marketing);
        if (avatar) {
            formData.append('avatar', avatar);
        }

        const record = await pb.collection('users').create(formData);
        await pb.collection('users').requestVerification(email);
        navigate('/activate');
    };

    const handleAvatarChange = (e) => {
        setAvatar(e.target.files[0]);
    };

    return (
        <div className="flex items-center justify-center h-screen bg-gray-200">
            <form onSubmit={handleSignup} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <h1 className="text-3xl text-center mb-6 py-8">Sign Up</h1>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                        *Username
                    </label>
                    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Username" onChange={(e) => setUsername(e.target.value)} />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                        *Email
                    </label>
                    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                        *Password
                    </label>
                    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="password" type="password" placeholder="******************" onChange={(e) => setPassword(e.target.value)} />
                </div>
                <div className="mb-6">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="passwordConfirm">
                        *Confirm Password
                    </label>
                    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="passwordConfirm" type="password" placeholder="******************" onChange={(e) => setPasswordConfirm(e.target.value)} />
                </div>
                <div className="mb-6">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="avatar">
                        Avatar
                    </label>
                    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="avatar" type="file" onChange={handleAvatarChange} />
                </div>
                <div className="flex flex-col mb-6">
                    <div className="mb-6 flex items-center">
                        <input className="form-checkbox h-5 w-5 text-blue-600" id="marketing" type="checkbox" defaultChecked={marketing} onChange={(e) => setMarketing(e.target.checked)} />
                        <label className="ml-2 block text-gray-700 text-sm font-bold" htmlFor="marketing">
                            Marketing
                        </label>
                        <div className="pl-2">
                            <Message text="
                            Exclusive news and discounts.
                            " />
                        </div>
                    </div>
                    <div className="mb-6 flex items-center">
                        <input className="form-checkbox h-5 w-5 text-blue-600" id="beta" type="checkbox" defaultChecked={beta} disabled />
                        <label className="ml-2 block text-gray-700 text-sm font-bold" htmlFor="beta">
                            *Beta
                        </label>
                        <div className="pl-2">
                            <Message text="
                            Platform is subject to change.
                            " />
                        </div>
                    </div>
                    <div className="mb-6 flex items-center">
                        <input className="form-checkbox h-5 w-5 text-blue-600" id="gdpr" type="checkbox" onChange={(e) => setGdpr(e.target.checked)} />
                        <label className="ml-2 block text-gray-700 text-sm font-bold" htmlFor="gdpr">
                            *GDPR
                        </label>
                        <a href="https://www.pylar.org/user/privacy" className="ml-2 text-blue-500" target="_blank" rel="noopener noreferrer">Read more</a>
                    </div>
                    <div className="mb-6 flex items-center">
                        <input className="form-checkbox h-5 w-5 text-blue-600" id="terms" type="checkbox" onChange={(e) => setTerms(e.target.checked)} />
                        <label className="ml-2 block text-gray-700 text-sm font-bold" htmlFor="terms">
                            *Terms
                        </label>
                        <a href="https://www.pencil.works/terms" className="ml-2 text-blue-500" target="_blank" rel="noopener noreferrer">Read more</a>
                    </div>
                </div>
                <div className="flex items-center justify-between">
                    <button className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${(!gdpr || !terms || !beta) && 'opacity-50 cursor-not-allowed'}`} type="submit" disabled={!gdpr || !terms || !beta}>
                        Sign Up
                    </button>
                    <a className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800" href="/login">
                        I have an account
                    </a>
                </div>
                {/* FreeArea */}
                <div className="py-8">
                    <div className="fixed bottom-0 left-0 right-0 bg-white opacity-0 text-center p-4 text-xl text-center rounded-t-lg shadow-lg">

                    </div>
                </div>
                {/* FreeArea */}
            </form>
        </div>
    );
}

export default Signup;