// Search.js
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

const Search = ({ onSearch }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [lastYPos, setLastYPos] = useState(0);
    const [shouldShowActions, setShouldShowActions] = useState(true);

    useEffect(() => {
        function handleScroll() {
            const yPos = window.scrollY;
            const isScrollingUp = yPos < lastYPos;

            setShouldShowActions(isScrollingUp);
            setLastYPos(yPos);
        }

        window.addEventListener("scroll", handleScroll, false);

        return () => {
            window.removeEventListener("scroll", handleScroll, false);
        };
    }, [lastYPos]);

    return (
        <div className="flex flex-row justify-center items-center flex-wrap">
            <div className="items-center justify-center flex bg-gray-200">
                <motion.div
                    className="fixed bottom-5 bg-black text-white p-4 text-xl text-center rounded-full shadow-lg"
                    initial={{ y: 1000 }}
                    animate={{ y: shouldShowActions ? 0 : 1000 }}
                    transition={{ type: "spring", stiffness: 100, damping: 20 }}
                >
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                            onSearch(e.target.value);
                        }}
                        placeholder="Search with number, name, or set name... (separate with commas)"
                        className="bg-[#00000020] text-blue-600 placeholder:text-blue-600 p-2 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </motion.div>
            </div>
        </div>
    );
};

export default Search;
