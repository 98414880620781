import React, { useState } from 'react';
import PocketBase from 'pocketbase';
import MenuMobile from "../handheld/MenuMobile";
import { toast } from 'wc-toast';

const pb = new PocketBase('https://t004080.app-palma.teide.app/');

const countries = [
    'Spain',
    'Cyprus',
    'United Kingdom',
    'France',
    'Germany',
    'Greece',
    'Albania',
    'Andorra',
    'Armenia',
    'Austria',
    'Azerbaijan',
    'Belarus',
    'Belgium',
    'Bosnia and Herzegovina',
    'Bulgaria',
    'Croatia',
    'Czechia',
    'Denmark',
    'Estonia',
    'Finland',
    'Georgia',
    'Hungary',
    'Iceland',
    'Ireland',
    'Italy',
    'Kazakhstan',
    'Kosovo',
    'Latvia',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Malta',
    'Moldova',
    'Monaco',
    'Montenegro',
    'Netherlands',
    'North Macedonia',
    'Norway',
    'Poland',
    'Portugal',
    'Romania',
    'San Marino',
    'Serbia',
    'Slovakia',
    'Slovenia',
    'Sweden',
    'Switzerland',
    'Turkey',
    'Ukraine',
    'Vatican City',
];
const comunidad = [
    'Bilbao Vizcaya',
    'Madrid',
    'Barcelona',
    'Adra Almería',
    'Aguadulce Almería',
    'Albacete',
    'Alba de Tormes Salamanca',
    'Alberca Salamanca',
    'Albir Alicante',
    'La Albufeira Valencia',
    'Alcalá de Henares Madrid',
    'Alhama Granada',
    'Alicante',
    'Allora Málaga',
    'Almería',
    'Almerimar Almería',
    'Almodovar Córdoba',
    'Almuñecar Granada',
    'La Alpujarra Granada',
    'Altamira La Cueva de Altamira',
    'Altea Alicante',
    'Antequera Málaga',
    'La Antilla Huelva',
    'Aranjuez Madrid',
    'Arenys de Mar Barcelona',
    'Ávila',
    'Avilés',
    'Badalona Barcelona',
    'Baelo Claudia Cádiz',
    'Baena Córdoba',
    'Barciense Toledo',
    'Las Batuecas Salamanca',
    'Baza Granada',
    'Bejar Salamanca',
    'Belagua Pamplona',
    'Belorado Burgos',
    'Benalmádena Málaga',
    'Benidorm Alicante',
    'Bolonia Cádiz',
    'Borja Zaragoza',
    'Briviesca Burgos',
    'Bubión Granada',
    'Burgos',
    'Burjasot Valencia',
    'Cabra Córdoba',
    'Cabrera',
    'Cáceres',
    'Cádiz',
    'Calahonda Granada',
    'La Calahorra Granada',
    'Calpe Alicante',
    'El Campello Alicante',
    'Candelario Salamanca',
    'Capileira Granada',
    'Carihuela Málaga',
    'Cariñena Zaragoza',
    'Carmona Sevilla',
    'Carratraca Málaga',
    'Cartagena Murcia',
    'Casarabonela Málaga',
    'Casares Málaga',
    'Caspe Zaragoza',
    'Castell de Ferro Granada',
    'Castellón',
    'Castillejo Granada',
    'Castillo de Javier Pamplona',
    'Castrojeriz Burgos',
    'Catalayud Zaragoza',
    'Chiclana Cádiz',
    'Chinchón Madrid',
    'Ciudad Real',
    'Ciudad Rodrigo Salamanca',
    'Conil de La Frontera Cádiz',
    'Consuegra Toledo',
    'Córdoba',
    'A Coruña',
    'Costa de Almería',
    'Costa del Azahar',
    'Costa Ballena Cádiz',
    'Costa Blanca',
    'Costa Brava',
    'Costa Cálida',
    'Costa de la Luz',
    'Costa del Sol',
    'Costa Dorada',
    'Costa Vasca',
    'Costa Verde',
    'Covarrubias Burgos',
    'Cuenca',
    'Cugat Barcelona',
    'Daroca Zaragoza',
    'Denia Alicante',
    'Doñana Parque Nacional',
    'Écija Sevilla',
    'Ejea de los Caballeros Zaragoza',
    'Escalona Toledo',
    'Escatrón Zaragoza',
    'El Escorial Madrid',
    'Espejo Córdoba',
    'Espinosa de los Monteros Burgos',
    'Esquivias Toledo',
    'Estella Pamplona',
    'Estepona Málaga',
    'Figueres Gerona',
    'Finestrat Alicante',
    'Formentera',
    'Frías Burgos',
    'Fuengirola Málaga',
    'Fuerteventura',
    'Fuentetodos Zaragoza',
    'Gijón',
    'Gerona',
    'La Gomera',
    'Granada',
    'Gran Canaria',
    'Guadalajara',
    'Guadalupe Cáceres',
    'Guadamur Toledo',
    'Guadix Granada',
    'Guardamar del Segura Alicante',
    'La Herradura Granada',
    'El Hierro',
    'Huelva',
    'Huesca',
    'Huéscar Granada',
    'Ibiza',
    'Illanoz Granada',
    'Illescas Toledo',
    'Illora Granada',
    'Isla Canela Huelva',
    'Isla Cristina Huelva',
    'Islantilla Huelva',
    'Itálica Sevilla',
    'Isaba Pamplona',
    'Itero Burgos',
    'Jaén',
    'Játiva Valencia',
    'Jávea Alicante',
    'Jerez de la Frontera Cádiz',
    'Lagartera Toledo',
    'Lanjarón Granada',
    'Lanzarote',
    'León',
    'Lérida',
    'Lerma Burgos',
    'Lloret de Mar Gerona',
    'Logroño',
    'Loja Granada',
    'Lugo',
    'Málaga',
    'Mallorca',
    'La Manga Murcia',
    'Maqueda Toledo',
    'Marbella Málaga',
    'Mataró Barcelona',
    'Matalascañas Huelva',
    'Mazarrón Murcia',
    'Mecina Granada',
    'Medina Azahara Córdoba',
    'Medina de Pomar Burgos',
    'Menorca',
    'Mérida Badajoz',
    'Mijas Málaga',
    'Miranda del Castañar Salamanca',
    'Mogarraz Salamanca',
    'Montefrío Granada',
    'Montilla Córdoba',
    'Montoro Córdoba',
    'Montserrat Barcelona',
    'Moradillo de Sedano Burgos',
    'Morella Castellón',
    'Moriles Córdoba',
    'Murcia',
    'Nerja Málaga',
    'Nuevalos Zaragoza',
    'Nuevo Portil Huelva',
    'Ocaña Toledo',
    'Ochagavía Pamplona',
    'Ojén Málaga',
    'Olite Pamplona',
    'Olmillos Burgos',
    'Ourense',
    'Orgaz Toledo',
    'Oropesa Castellón',
    'Oropesa Toledo',
    'Orvija Granada',
    'Osuna Sevilla',
    'Oviedo',
    'Palencia',
    'La Palma',
    'Palmar Valencia',
    'Palos de la Frontera Huelva',
    'Pampaneira Granada',
    'Pamplona',
    'Penedés Barcelona',
    'Peñaranda del Duero Burgos',
    'Peñíscola Castellón',
    'Picos de Europa Parque Nacional Pilar de Horadada Alicante',
    'Pinos Puente Granada',
    'Pizarra Málaga',
    'Plasencia Cáceres',
    'Pontevedra',
    'Priego Córdoba',
    'Puentearenas Burgos',
    'Puente del Arzobispo Toledo',
    'Puente la Reina Pamplona',
    'Puerto Banús Málaga',
    'El Puerto de Santa María Cádiz',
    'Punta Umbría Huelva',
    'Quintanar de la Orden Toledo',
    'Quintanilla de las Viñas Burgos',
    'La Rábida Huelva',
    'Rebolledo de la Torre Burgos',
    'Redecilla del Camino Burgos',
    'Rincón de la Victoria Málaga',
    'Roquetas de Mar Almería',
    'El Rompido Huelva',
    'Roncal Pamplona',
    'Roncesvalles Pamplona',
    'Ronda Málaga',
    'Rota Cádiz',
    'Sabadell Barcelona',
    'Sagunto Valencia',
    'San José del Monte Salamanca',
    'San Juan de Ortega Burgos',
    'Salamanca',
    'Salas de los Infantes Burgos',
    'Salobreña Granada',
    'Sangüesa Pamplona',
    'San Pedro de Alcántara Málaga',
    'San Salvador de Leyre Pamplona',
    'San Sebastián Guipuzcoa',
    'Santa Fe Granada',
    'Santa Pola Alicante',
    'Santander',
    'Santiago de Compostela A Coruña',
    'Santillana del Mar Santander',
    'Santiponce Sevilla',
    'Sant Pere de Ribes Barcelona',
    'Sant Sadurní d Anoia Barcelona',
    'Sedano Burgos',
    'Segovia',
    'Sequeros Salamanca',
    'Sevilla',
    'Sigüenza Guadalajara',
    'Sierra Nevada Granada',
    'Silos Burgos',
    'Sitges Barcelona',
    'Soportuja Granada',
    'Soria',
    'Sos del Rey Católico Zaragoza',
    'Tabarca Alicante',
    'Tafalla Pamplona',
    'Talavera de la Reina Toledo',
    'Tarazona Zaragoza',
    'Tarifa Cádiz',
    'Tarragona',
    'Tauste Zaragoza',
    'Tembleque Toledo',
    'Tenerife',
    'Terrasa Barcelona',
    'Teruel',
    'El Toboso Toledo',
    'Toledo',
    'Torcal de Antequera Málaga',
    'Torremolinos Málaga',
    'Torrenueva Granada',
    'Torrevieja Alicante',
    'Torrijos Toledo',
    'Tossa de Mar Gerona',
    'El Toyo Almería',
    'Trévelez Granada',
    'Trujillo Cáceres',
    'Tudela Pamplona',
    'Ujue Pamplona',
    'Uncastillo Zaragoza',
    'Valencia',
    'Valladolid',
    'Valor Granada',
    'Vélez Málaga',
    'Vera Almería',
    'Viana Pamplona',
    'Vigo Pontevedra',
    'Vilafranca Barcelona',
    'Villafranca Burgos',
    'Vitoria-Gasteiz Álava',
    'Yesa Pamplona',
    'Yesa Zaragoza',
    'Zahara de Los Atunes Cádiz',
    'Zamora',
    'Zaragoza'
];

const NewCorrespondence = () => {
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [street, setStreet] = useState('');
    const [pc, setPc] = useState('');
    const [city, setCity] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedComunidad, setSelectedComunidad] = useState('');
    const [cardmarketuser, setCardmarketuser] = useState('');
    const [date, setDate] = useState('');
    const [photofront, setPhotofront] = useState(null);
    const [photoback, setPhotoback] = useState(null);
    const formData = {
        user: pb.authStore.model ? pb.authStore.model.id : null,
        name,
        surname,
        street,
        pc,
        city,
        selectedCountry,
        selectedComunidad,
        cardmarketuser,
        date,
        photofront,
        photoback
    };

    const handleCountryChange = (event) => {
        setSelectedCountry(event.target.value);
    };

    const handleComunidadChange = (event) => {
        setSelectedComunidad(event.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const user = pb.authStore.model ? pb.authStore.model.id : null; // Get the user ID

        try {
            const record = await pb.collection('arrivals').create({
                user: formData.user, // Add the user ID
                name: formData.name,
                surname: formData.surname,
                street: formData.street,
                pc: formData.pc,
                city: formData.city,
                country: formData.selectedCountry,
                comunidad: formData.selectedComunidad,
                cardmarketuser: formData.cardmarketuser,
                date: formData.date,
                photofront: photofront,
                photoback: photoback
            });
            toast.success('Correspondence created successfully');
            /* make blank the form */
            setName('');
            setSurname('');
            setStreet('');
            setPc('');
            setCity('');
            setSelectedCountry('');
            setSelectedComunidad('');
            setCardmarketuser('');
            setDate('');
            setPhotofront(null);
            setPhotoback(null);
        } catch (error) {
            toast.error('Error creating correspondence');
        }
    };

    return (
        <div className="flex flex-col justify-center p-5">
            <body>
                <wc-toast></wc-toast>
            </body>
            <MenuMobile />
            <form onSubmit={handleSubmit}>
                <div className="flex flex-col justify-center space-y-5">
                    <h1 className="text-2xl font-bold">New Correspondence</h1>
                    <div className="flex flex-row justify-between space-x-5">
                        <p className="text-sm text-gray-500">Name</p>
                        <p className="text-sm text-gray-500">Surname</p>
                    </div>
                    <div className="flex flex-row justify-between space-x-5">
                        <input className="bg-blue-200 text-md px-2 py-1 rounded-md shadow-xl w-1/2 hover:shadow-2xl"
                            type="text" value={name} onChange={(e) => setName(e.target.value)}
                            placeholder="Name"
                        />
                        <input className="bg-blue-200 text-md px-2 py-1 rounded-md shadow-xl w-1/2 hover:shadow-2xl"
                            type="text" value={surname} onChange={(e) => setSurname(e.target.value)}
                            placeholder="Surname"
                        />
                    </div>
                    <div className="flex flex-row justify-between space-x-5">
                        <p className="text-sm text-gray-500">Street</p>
                        <p className="text-sm text-gray-500">Postal Code</p>
                    </div>
                    <div className="flex flex-row justify-between space-x-5">
                        <input className="bg-blue-200 text-md px-2 py-1 rounded-md shadow-xl w-1/2 hover:shadow-2xl"
                            type="text" value={street} onChange={(e) => setStreet(e.target.value)}
                            placeholder="Street"
                        />
                        <input className="bg-blue-200 text-md px-2 py-1 rounded-md shadow-xl w-1/2 hover:shadow-2xl"
                            type="text" value={pc} onChange={(e) => setPc(e.target.value)}
                            placeholder="Postal Code"
                        />
                    </div>
                    <div className="flex flex-row justify-between space-x-5">
                        <p className="text-sm text-gray-500">Country</p>
                        <p className="text-sm text-gray-500">City</p>
                    </div>
                    <div className="flex flex-row justify-between space-x-5">
                        <select value={selectedCountry} onChange={handleCountryChange}
                            className="bg-blue-200 text-md px-2 py-1 rounded-md shadow-xl w-1/2 hover:shadow-2xl"
                        >
                            {countries.map((country) => (
                                <option key={country} value={country}>
                                    {country}
                                </option>
                            ))}
                        </select>
                        <input className="bg-blue-200 text-md px-2 py-1 rounded-md shadow-xl w-1/2 hover:shadow-2xl"
                            type="text" value={city} onChange={(e) => setCity(e.target.value)}
                            placeholder="City"
                        />
                    </div>
                    {/* only if select spain, show comunidad */}
                    {selectedCountry === 'Spain' && (
                        <p className="text-sm text-gray-500">Comunidad Autónoma</p>
                    )}
                    {selectedCountry === 'Spain' && (
                        <select value={selectedComunidad} onChange={handleComunidadChange}
                            className="bg-blue-200 text-md px-2 py-1 rounded-md shadow-xl w-full hover:shadow-2xl"
                        >
                            {comunidad.map((comunidad) => (
                                <option key={comunidad} value={comunidad}>
                                    {comunidad}
                                </option>
                            ))}
                        </select>
                    )}
                    <div className="flex flex-row justify-between space-x-5">
                        <p className="text-sm text-gray-500">Cardmarket Order</p>
                        <p className="text-sm text-gray-500">Date</p>
                    </div>
                    <div className="flex flex-row justify-between space-x-5">
                        <input className="bg-blue-200 text-md px-2 py-1 rounded-md shadow-xl w-1/2 hover:shadow-2xl"
                            type="text" value={cardmarketuser} onChange={(e) => setCardmarketuser(e.target.value)}
                            placeholder="Cardmarket Order"
                        />
                        <input className="bg-blue-200 text-md px-2 py-1 rounded-md shadow-xl w-1/2 hover:shadow-2xl"
                            type="date" value={date} onChange={(e) => setDate(e.target.value)}
                            placeholder="Date"
                        />
                    </div>
                    <div className="flex flex-row justify-between space-x-5">
                        <p className="text-sm text-gray-500">Photo Front</p>
                        <p className="text-sm text-gray-500">Photo Back</p>
                    </div>
                    <div className="flex flex-row justify-between space-x-5">
                        <input className="bg-blue-200 text-md px-2 py-1 rounded-md shadow-xl w-full hover:shadow-2xl"
                            type="file" onChange={(e) => setPhotofront(e.target.files[0])}
                            placeholder="Front of the front letter"
                        />

                        <input className="bg-blue-200 text-md px-2 py-1 rounded-md shadow-xl w-full hover:shadow-2xl"
                            type="file" onChange={(e) => setPhotoback(e.target.files[0])}
                            placeholder="Back of the front letter"
                        />
                    </div>
                    <button className="text-md px-2 py-1 border-2 border-gray-300 rounded-md"
                        type="submit">Submit</button>
                </div>
            </form>
        </div>
    );
}

export default NewCorrespondence;