// ResponsiveDecider.js
import React, { useState, useEffect } from 'react';

const ResponsiveDecider = ({ mobileComponent, desktopComponent }) => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkIfMobile = () => {
            /* cualquier mobil tanto vertical como horizontal */
            setIsMobile(window.innerWidth < 461 || window.innerHeight < 461);
            // setIsMobile(window.innerWidth < 279);
            // setIsMobile(window.innerWidth > 279 && window.innerWidth < 461 && window.innerHeight > 279 && window.innerHeight < 461);
        };

        // Check on mount
        checkIfMobile();

        // Add event listener
        window.addEventListener('resize', checkIfMobile);

        // Cleanup
        return () => window.removeEventListener('resize', checkIfMobile);
    }, []);

    return (
        <>
            {isMobile ? mobileComponent : desktopComponent}
        </>
    );
};

export default ResponsiveDecider;
