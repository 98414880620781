import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Activate from '../airport/control/Activate'; // Assuming Activate.js is in the same directory
import PocketBase from 'pocketbase';

const pb = new PocketBase('https://t004080.app-palma.teide.app/');

const App = () => {
	const navigate = useNavigate();
	const user = pb.authStore.model;

	useEffect(() => {
		if (user) {
			if (user.verified) {
				navigate('/manage');
			} else {
				navigate('/activate');
			}
		}
	}, [user, navigate]);

	return (
		<div>
			<Activate />
		</div>
	);
};

export default App;