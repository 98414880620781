import React, { useState } from 'react';
import { motion } from 'framer-motion';

const Message = ({ text }) => {
    const [show, setShow] = useState(false);

    return (
        <div className="flex flex-row p-2 items-center justify-between bg-blue-100 border-l-4 rounded-xl border-blue-700 text-blue-700">
            <div className="text-blue-700" onClick={() => setShow(!show)}>
                {show ? (
                    <svg height="21" viewBox="0 0 21 21" width="21" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" transform="translate(2 2)"><circle cx="8.5" cy="8.5" r="8" /><g transform="matrix(0 1 -1 0 17 0)"><path d="m5.5 11.5 6-6" /><path d="m5.5 5.5 6 6" /></g></g></svg>
                ) : (
                    <svg height="21" viewBox="0 0 21 21" width="21" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" transform="translate(1 1)"><path d="m8.5 4.5c-1.65685425 0-3 1.34314575-3 3v5c0 1.6568542 1.34314575 3 3 3l2.468-.001 1.7152977 2.4298518c.1639804.2320786.4176379.3818336.6960544.4156462l.1206479.0072945c.5128358 0 .9355072-.3860402.9932723-.8833789l.0067277-.1166211.001-1.8527925.999.001c1.6568542 0 3-1.3431458 3-3v-5c0-1.65685425-1.3431458-3-3-3z" /><path d="m5.5 12.5-2 2v-4h-.90625561c-1.09544195 0-1.98704628-.88123276-1.9998631-1.97659973l-.07020561-6c-.01292361-1.10449389.87196894-2.01033976 1.97646283-2.02326337.00779976-.00009127.01559998-.0001369.02340027-.0001369h8.97646122c1.1045695 0 2 .8954305 2 2v2" /></g></svg>
                )}
            </div>
            {show && (
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 20 }}
                    transition={{ duration: 0.2 }}
                    className=""
                >
                    <div className="flex flex-row items-center space-x-2">
                        <p className="text-blue-700 px-2 ">{text}</p>
                    </div>
                </motion.div>
            )}
        </div>
    );
};

export default Message;