import React, { useState } from 'react';
import axios from 'axios';
import PocketBase from 'pocketbase';

const pb = new PocketBase('https://t004080.app-palma.teide.app/');

const Sync = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const saveCardToPocketBase = async (card) => {
    const cardEeid = card.name + "-" + card.number + "/" + card.set.printedTotal + "-" + card.set.id + "-" + card.regulationMark;
    const cardId = card.number + "/" + card.set.printedTotal; // Create a unique cardId
    console.log(`Saving card: ${card.name} with cardId: ${cardId}`); // Log before saving
    const cardData = {
      cardfile: card,
      /* Name */
      name: card.name,
      /* Id */
      cardId: cardId,
      /* regulation mark */
      cardRegulationMark: card.regulationMark,
      cardEeid: cardEeid,
      /* Set */
      setid: card.set.id,
      setname: card.set.name,
      setseries: card.set.series,
      setprintedtotal: card.set.printedTotal,
      settotal: card.set.total,
      setlegalitiesstandard: card.set.legalities.standard,
      setlegalitiesunlimited: card.set.legalities.unlimited,
      setlegalitiesexpanded: card.set.legalities.expanded,
      setptcgoCode: card.set.ptcgoCode,
      setreleaseDate: card.set.releaseDate,
      setupdatedAt: card.set.updatedAt,
      setimagesymbol: card.set.images.symbol,
      setimagelogo: card.set.images.logo,
      /* number, artist y rarity */
      number: card.number,
      artist: card.artist,
      rarity: card.rarity,
      /* nationalPokedexNumbers */
      nationalPokedexNumbers: Array.isArray(card.nationalPokedexNumbers) ? card.nationalPokedexNumbers.join(', ') : '',
      nationalPokedexNumbers_one: Array.isArray(card.nationalPokedexNumbers) && card.nationalPokedexNumbers[0] ? card.nationalPokedexNumbers[0] : '',
      nationalPokedexNumbers_two: Array.isArray(card.nationalPokedexNumbers) && card.nationalPokedexNumbers[1] ? card.nationalPokedexNumbers[1] : '',
      nationalPokedexNumbers_three: Array.isArray(card.nationalPokedexNumbers) && card.nationalPokedexNumbers[2] ? card.nationalPokedexNumbers[2] : '',
      /* General */
      hp: card.hp,
      rules: card.rules?.join(', ') || '',
      types: card.types,
      attack_one: card.attacks?.[0]?.name || '',
      attack_one_damage: card.attacks?.[0]?.damage || '',
      attack_one_description: card.attacks?.[0]?.text || '',
      attack_one_energy_cost_type_one: card.attacks?.[0]?.cost[0] || '',
      attack_one_energy_cost_type_two: card.attacks?.[0]?.cost[1] || '',
      attack_one_energy_cost_type_three: card.attacks?.[0]?.cost[2] || '',
      attack_one_energy_cost_type_four: card.attacks?.[0]?.cost[3] || '',
      attack_one_energy_cost_type_five: card.attacks?.[0]?.cost[4] || '',
      attack_two: card.attacks?.[1]?.name || '',
      attack_two_damage: card.attacks?.[1]?.damage || '',
      attack_two_description: card.attacks?.[1]?.text || '',
      attack_two_energy_cost_type_one: card.attacks?.[1]?.cost[0] || '',
      attack_two_energy_cost_type_two: card.attacks?.[1]?.cost[1] || '',
      attack_two_energy_cost_type_three: card.attacks?.[1]?.cost[2] || '',
      attack_two_energy_cost_type_four: card.attacks?.[1]?.cost[3] || '',
      attack_two_energy_cost_type_five: card.attacks?.[1]?.cost[4] || '',
      hability: card.abilities?.[0]?.name || '',
      hability_description: card.abilities?.[0]?.text || '',
      weakness: card.weaknesses?.[0]?.type || '',
      resistance: card.resistances?.[0]?.type || '',
      retreatCost: card.retreatCost?.join(', ') || '',
      /* legalities */
      legalitiesstandard: card.legalities.standard,
      legalitiesunlimited: card.legalities.unlimited,
      legalitiesexpanded: card.legalities.expanded,
      /* images */
      imagesmall: card.images.small,
      imageslarge: card.images.large,
    };
    try {
      const saveResponse = await pb.collection('cards').create(cardData);
      console.log(`Successfully saved: ${cardId}`, saveResponse); // Log on success
    } catch (err) {
      console.error(`Error saving card ${cardId} to PocketBase:`, err);
    }
  };

  const fetchCardsByName = async (name) => {
    setLoading(true);
    setError('');
    let page = 1;
    let cards = [];
    try {
      while (true) {
        const response = await axios.get(`https://api.pokemontcg.io/v2/cards?q=name:${name}*&page=${page}&pageSize=250`, {
          headers: {
            'X-Api-Key': process.env.REACT_APP_POKEMON_TCG_API_KEY,
          },
        });
        cards = [...cards, ...response.data.data];
        if (response.data.data.length < 250) {
          break; // No more results
        }
        page++;
      }
      setCards(cards);
      for (const card of cards) {
        await saveCardToPocketBase(card); // Save each card to PocketBase sequentially
      }
    } catch (err) {
      console.error('Error fetching cards:', err);
      setError('Failed to fetch cards. Please try again.');
      setCards([]);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      fetchCardsByName(searchTerm);
    }
  };

  const handleCardSelect = (cardId) => {
    const card = cards.find(card => card.id === cardId);
    setSelectedCard(card);
  };

  return (
    <div className="container mx-auto px-4">
      {/* Search form */}
      <form onSubmit={handleSubmit} className="flex items-center justify-center mt-5">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Enter Pokemon Name"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
        <button type="submit" className="ml-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
          Search
        </button>
      </form>

      {/* Display loading/error messages */}
      {loading && <p className="text-center mt-5">Loading...</p>}
      {error && <p className="text-center mt-5 text-red-500">{error}</p>}
      <div className="flex flex-col items-center mt-5">
        <p className="text-center">Total: {cards.length}</p>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-5">
          {cards.map((card) => (
            <div key={card.id} onClick={() => handleCardSelect(card.id)} className="cursor-pointer">
              {card.name} - {card.number}/{card.set.printedTotal}
              <img src={card.images.small} alt={card.name} className="w-full h-auto" />
            </div>
          ))}
        </div>
        {selectedCard && (
          <div className="flex flex-row justify-between mt-5 max-w-4xl">
            <div className="flex flex-col justify-between">
              <h2 className="text-2xl font-bold">{selectedCard.name}</h2>
              <p><strong>Type:</strong> {selectedCard.types}</p>
              <img src={selectedCard.images.large} alt={selectedCard.name} className="w-1/4 h-auto" />
              <p><strong>Set:</strong> {selectedCard.set.name}</p>
              <p><strong>Number:</strong> {selectedCard.number}</p>
              <p><strong>Artist:</strong> {selectedCard.artist}</p>
            </div>
            <div className="flex flex-col justify-between">
              {selectedCard.hp && (<p><strong>HP:</strong> {selectedCard.hp}</p>)}
              {selectedCard.rarity && (<p className="hidden"><strong>Rarity:</strong> {selectedCard.rarity}</p>)}
              {selectedCard.attacks && (
                <div>
                  <h3 className="font-bold">Attacks:</h3>
                  {selectedCard.attacks.map((attack, index) => (
                    <div key={index} className="mb-2">
                      <p><strong>Name:</strong> {attack.name}</p>
                      <p><strong>Damage:</strong> {attack.damage}</p>
                      {/*                       <p><strong>Cost:</strong> {attack.cost.join(', ')}</p>
 */}                      {attack.text && <p><strong>Text:</strong> {attack.text}</p>}
                    </div>
                  ))}
                </div>
              )}
              {selectedCard.weaknesses && (
                <div>
                  <h3 className="font-bold">Weaknesses:</h3>
                  {selectedCard.weaknesses.map((weakness, index) => (
                    <p key={index}>{weakness.type} {weakness.value}</p>
                  ))}
                </div>
              )}
              {selectedCard.resistances && (
                <div>
                  <h3 className="font-bold">Resistances:</h3>
                  {selectedCard.resistances.map((resistance, index) => (
                    <p key={index}>{resistance.type} {resistance.value}</p>
                  ))}
                </div>
              )}
              {selectedCard.abilities && (
                <div>
                  <h3 className="font-bold">Abilities:</h3>
                  {selectedCard.abilities.map((ability, index) => (
                    <p key={index}><strong>Name:</strong> {ability.name}</p>
                  ))}
                </div>
              )}
              {selectedCard.retreatCost && (
                <p><strong>Retreat Cost:</strong> {selectedCard.retreatCost.join(', ')}</p>
              )}
              {selectedCard.rules && (
                <div>
                  <h3 className="font-bold">Rules:</h3>
                  {selectedCard.rules.map((rule, index) => (
                    <p key={index}>{rule}</p>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Sync;
