// Search.js
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import MaterialVerticalMenu from "./MaterialVerticalMenu";

const MenuMobile = () => {
    const [lastYPos, setLastYPos] = useState(0);
    const [shouldShowActions, setShouldShowActions] = useState(true);

    useEffect(() => {
        function handleScroll() {
            const yPos = window.scrollY;
            const isScrollingUp = yPos < lastYPos;

            setShouldShowActions(isScrollingUp);
            setLastYPos(yPos);
        }

        window.addEventListener("scroll", handleScroll, false);

        return () => {
            window.removeEventListener("scroll", handleScroll, false);
        };
    }, [lastYPos]);

    return (
        <div className="flex flex-row justify-center items-center flex-wrap">
            <motion.div
                className="fixed bottom-10 left-5 flex flex-col items-center text-white text-xl text-center rounded-full shadow-lg"
                initial={{ y: 1000 }}
                animate={{ y: shouldShowActions ? 0 : 1000 }}
                transition={{ type: "spring", stiffness: 100, damping: 20 }}
            >
                <MaterialVerticalMenu />
            </motion.div>
        </div>
    );
};

export default MenuMobile;
